<template>
  <div class="navBar">
    <div id="nav">
      <div class="logo">九伴娱乐</div>
      <div>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" router active-text-color="#409EFF">
          <el-menu-item index="1" route="home">首页</el-menu-item>
          <el-menu-item index="2" route="convention">直播公约</el-menu-item>
          <el-menu-item index="3" route="copyright">版权协议</el-menu-item>
<!--          <el-menu-item index="4" route="about">主播协议</el-menu-item>-->
          <el-menu-item index="5" route="self-examination">自审自查</el-menu-item>
          <el-menu-item index="6" route="about">关于我们</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
  <div style="height: 60px"></div>
  <router-view/>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '1',
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #222222;
}

.navBar {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  /*height: 60px;*/
  top: 0;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  /*border-bottom: 1px solid #ededed;*/
  z-index: 99;
}

#nav {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  align-items: center;
  /*height: 80px;*/
  margin: 0 auto;
  /*padding: 30px;*/
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

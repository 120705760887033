import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/convention',
    name: 'convention',
    component: () => import(/* webpackChunkName: "about" */ '../views/convention.vue')
  },
  {
    path: '/copyright',
    name: 'copyright',
    component: () => import(/* webpackChunkName: "about" */ '../views/copyright.vue')
  },
  {
    path: '/self-examination',
    name: 'self-examination',
    component: () => import(/* webpackChunkName: "about" */ '../views/self-examination.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
